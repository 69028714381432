<template>
    <section>
      <div class="w-full bg-white shadow-md rounded-md p-4">
        <div class="xl:flex lg:justify-between">
          <div class="grid grid-cols-3 gap-8">
            <div>
              <p class="lg:text-xs">ID Recep</p>
              <div>
                <p class="font-bold text-md py-1 mt-2 w-full">
                  {{recepcion.id}}
                </p>
              </div>
            </div>
            <div>
              <p class="lg:text-xs">N° Guía</p>
              <div>
                <p class="font-bold text-gray-600 text-md py-1 mt-2 w-full">
                  <i class="pi pi-file text-gray-600 text-xxs"></i>
                  {{recepcion.numGuia}}
                </p>
              </div>
            </div>
            <div>
              <p class="lg:text-xs">N° Oficio</p>
              <div>
                <p class="font-bold text-gray-600 text-md py-1 mt-2 w-full">
                  <i class="pi pi-file text-gray-600 text-xxs"></i>
                  {{recepcion.numOficio}}
                </p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-8 my-2 lg:my-0">
            <div>
              <p class="lg:text-xs">Fecha de Envío</p>
              <div>
                <p class="font-bold text-md py-1 mt-2 w-full">
                  {{ recepcion.fechaEnvio }}
                </p>
              </div>
            </div>
            <div>
              <p class="lg:text-xs">Fecha de Entrega</p>
              <div>
                <p class="font-bold text-md py-1 mt-2 w-full">
                  {{ dayjs(recepcion.fecha_entrega).format('YYYY-MM-DD') }}
                </p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <div class="flex">
                <p>
                  Bodega Origen:
                </p>
                <img class="w-12 ml-2" src="../../../../../../../../../assets/images/arrow-right-long.svg" alt="" />
              </div>
              <div class="flex gap-2">
                <p class="font-bold text-md py-1 mt-2 w-full">
                  {{recepcion.bodOrigen}}
                </p>
                <p style="min-inline-size: max-content;" class="font-bold text-md py-1 mt-2 w-full">
                  {{recepcion.bodOrigenName}}
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Bodega Destino:
                </p>
              </div>
              <div class="flex gap-2">
                <p class="font-bold text-md py-1 mt-2 w-full">
                  {{recepcion.bodDestino}}
                </p>
                <p style="min-inline-size: max-content;" class="font-bold text-md py-1 mt-2 w-full">
                  {{recepcion.bodDestinoName}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
    import { ref, computed } from 'vue'
    import dayjs from 'dayjs'
    import RecepcionStore from '../../../../../store/recepcion.store'
    export default {
    name: 'ComponenteHeaderRecepcion',
    setup () {
      const busqueda = ref(true)
      const recepcion = computed(() => RecepcionStore.getters._recepcion)
      return {
        busqueda,
        recepcion,
        dayjs
      }
    }
  }
  </script>

  <style scoped>
  </style>
